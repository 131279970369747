import { defineComponent, reactive, toRefs, defineAsyncComponent, onActivated, onMounted, computed, nextTick } from 'vue';
import { useDebounce } from '@/hooks/core/useDebounce';
import dayjs from 'dayjs';
import { useRoute } from 'vue-router';
import { isEmpty } from '@/utils/is';
import API from "@/api/studentCard";
import posTypeOptions from '@/options/posTypeOptions';
import { useMessage } from '@/hooks/web/useMessage';
import { handleISExpired } from '@/utils';
export default defineComponent({
  name: 'MapSearchInput',
  components: {
    OrganizeSelect: defineAsyncComponent(() => import('@/components/OrganizeSelect/index.vue'))
  },
  emits: ['onSearch'],
  inheritAttrs: false,
  props: {
    btnLoading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const route = useRoute();
    const refData = reactive({
      defaultTime: [dayjs().format('YYYY-MM-DD 00:00:00'), dayjs().format('YYYY-MM-DD 23:59:59')],
      searchTime: [new Date(dayjs().format('YYYY-MM-DD 00:00:00')), new Date(dayjs().format('YYYY-MM-DD 23:59:59'))],
      posType: -1,
      searchStr: '',
      objectid: 0,
      mdtid: ''
    });
    const pathName = computed(() => route.name);
    const mdtid = computed(() => route.query.mdtid || '');
    function querySearch() {
      console.log("searchStr" + refData.searchStr);
      if (refData.objectid || refData.searchStr) {
        emit('onSearch', {
          searchTime: refData.searchTime,
          posType: refData.posType,
          objectid: refData.objectid,
          mdtid: refData.searchStr
        });
      } else {
        message.warning("当前设备未绑定");
      }
    }
    const [debounceOnSearch] = useDebounce(querySearch, 500, {
      immediate: true
    });
    function handleSelect(item) {
      if (item.expireStatus === 1) {
        handleISExpired();
        return false;
      }
      refData.searchStr = item.mdtid;
      if (item.objectid) {
        refData.objectid = item.objectid;
        debounceOnSearch();
      } else {
        message.warning("当前设备未绑定");
      }
    }
    function disabledDate(time) {
      return time.getTime() > Date.now();
    }
    const handleChange = item => {
      if (item.expireStatus === 1) {
        handleISExpired();
        return false;
      }
      refData.searchStr = item.mdtid;
      if (item.objectid) {
        refData.objectid = item.objectid;
        debounceOnSearch();
      } else {
        message.warning("当前设备未绑定");
      }
    };
    function autoSearchByRoute() {
      console.log("autoSearchByRoute" + JSON.stringify(route.query));
      if (!isEmpty(route.query)) {
        const data = route.query;
        refData.searchStr = data.mdtid;
        refData.objectid = Number(data.objectid);
        refData.mdtid = data.mdtid;
        refData.posType = -1;
        debounceOnSearch();
      }
    }
    //根据imei号查询carId
    const getImeiCardId = async () => {
      const {
        data
      } = await API.getByCardId({
        mdtid: mdtid.value
      });
      if (pathName.value == 'embedPageTrajectory') {
        refData.objectid = data.objectid;
        refData.searchStr = data.mdtid;
        debounceOnSearch();
      }
    };
    onActivated(() => autoSearchByRoute());
    onMounted(() => autoSearchByRoute());
    nextTick(() => {
      getImeiCardId();
    });
    return {
      ...toRefs(refData),
      handleSelect,
      debounceOnSearch,
      posTypeOptions: posTypeOptions.filter(p => ![1, 5].includes(p.value)),
      disabledDate,
      handleChange,
      pathName
    };
  }
});